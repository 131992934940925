import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import logo from "../Image/experiences.svg";
import mobo from "../Image/experiences.svg";
import experiences from "../Image/experiences.svg";

import closemark from "../Image/closemark.png";
import "./Navbar.scss";
import { NavLink, useHistory } from "react-router-dom";
import { fontWeight } from "@mui/system";
export default function NavSection({ active }) {
  console.log(active, "active");
  const [info, setinfo] = useState("Home");
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);
  const [navExpanded, satnavExpanded] = useState(false);
  const handleFunction = () => {
    history.push("/Events/Cities");
  };
  const handleFunction1 = () => {
    setExpanded(!expanded);
    history.push("/Events/Cities");
  };

  const meta = [
    {
      name: "About",
    },
    {
      name: "Apps",
    },
    {
      name: "Memories",
    },
    {
      name: "Investors",
    },
    {
      name: "Portal",
    },
    // {
    //   name:"Explorer"
    // }
  ];

  const data = [
    {
      name: "About",
    },
    {
      name: "Apps",
    },
    {
      name: "NetworkChain",
    },
    {
      name: "InfluenceCoin",
    },
    {
      name: "Investors",
    },
    // {
    //   name:"Explorer"
    // }
  ];
  const [store, setstore] = useState([]);

  useEffect(() => {
    setmedata("");

    setstore(meta);

    return () => {};
  }, [info]);

  const handler = async (e) => {
    setExpanded(!expanded);
    if (e === "About") {
      await setinfo(e);

      history.push(`/investors/about`);
    }
    if (e === "Portfolio") {
      await setinfo(e);
      history.push(`/investors/portfolio/sharetokens`);
    }
  };
  const handler1 = async (e) => {
    active = e;
    if (e === "About") {
      await setinfo(e);
      history.push(`/company/about/whoarewe`);
    }
    if (e === "Portfolio") {
      await setinfo(e);
      history.push(`/investors/portfolio/sharetokens`);
    }
    if (e === "Starter") {
      await setinfo(e);
      history.push(`/incubation`);
    }
  };

  const setNavExpanded = (expanded) => {
    satnavExpanded(expanded);
  };
  const [medata, setmedata] = useState("");
  const metamobilefun = () => {
    history.push("/metaversify");
    setmedata("meta");

    setExpanded(!expanded);
    setinfo("meta");
  };

  const metdeskfun = () => {
    history.push("/metaversify");
    setmedata("meta");

    setinfo("meta");
  };
  // return (
  //   <>
  //     <div className="custom_navbar1e mobo">
  //       <Navbar
  //         onToggle={setNavExpanded}
  //         expanded={expanded}
  //         expand="lg"
  //         variant="dark"
  //       >
  //         <Container className="p-0 mobile">

  //           <Navbar.Brand href="/">
  //             {/* {" "} */}

  //             <img src={logo} alt=""  style={{width:"200px",height: "40.26px"}}/>
  //           </Navbar.Brand>

  //           {expanded ? (
  //             <Navbar.Toggle
  //               onClick={() => setExpanded(!expanded)}
  //               aria-controls="offcanvasNavbar"
  //             >
  //               <img style={{ width: "17px" }} src={closemark} alt="" />
  //             </Navbar.Toggle>
  //           ) : (
  //             <Navbar.Toggle
  //               onClick={() => setExpanded(!expanded)}
  //               aria-controls="offcanvasNavbar"
  //             />
  //           )}

  //           <img
  //             className="edit"
  //             onClick={() => history.push(`/`)}
  //         style={{width:"200px",height: "40.26px"}}
  //             alt=""
  //             src={mobo}
  //           />

  //           {expanded ? (
  //             <Navbar.Collapse id="responsive-navbar-nav">
  //               {/* <Nav className="me-auto">

  //   </Nav> */}
  //               <Nav>
  //                 {store.map((item) => {
  //                   return (
  //                     <Nav.Link
  //                       onClick={() => handler(item.name)}
  //                       style={
  //                         info === item.name
  //                           ? { fontWeight: "bold", fontSize: "33px" }
  //                           : { fontWeight: "400" }
  //                       }
  //                       className="buttons"
  //                     >
  //                       {item.name}
  //                     </Nav.Link>
  //                   );
  //                 })}
  //               </Nav>

  //             </Navbar.Collapse>
  //           ) : (
  //             ""
  //           )}
  //         </Container>
  //       </Navbar>
  //     </div>

  //     <div className="custom_navbar1e desk">
  //       <Navbar expand="lg" variant="dark">
  //         <Container fluid className="p-0 mobile">
  //           <Navbar.Brand href="/">
  //             {" "}
  //             <img src={logo} alt=""  style={{width:"225px",height: "40.26px"}}/>
  //           </Navbar.Brand>
  //           <div className="InputBox">
  //           <input type="text"
  //             placeholder="Search Viral Group...."
  //             />
  //           </div>

  //           <Navbar.Toggle aria-controls="offcanvasNavbar" />

  //           <img className="edit"  style={{width:"225px",height: "40.26px"}} src={logo} alt="" />

  //           <Navbar.Collapse id="responsive-navbar-nav"

  //           >
  //             <Nav className="me-auto"></Nav>
  //             <Nav className="Navbar-all-btn">
  //               {store.map((item) => {
  //                 return (
  //                   <div

  //                     className="buttons"
  //                     // style={
  //                     //   "Explorer" === item.name
  //                     //     ? {background: "#CF1A38",color:"white"}
  //                     //     : {color:'black'}
  //                     // }

  //                   >
  //                    <div onClick={() => handler1(item.name)}
  //                     style={
  //                       info === item.name
  //                         ? {fontWeight:'bold',color:'black'}
  //                         : {color:'black'}
  //                     }
  //                     // style={
  //                     //   info === item.name
  //                     //     ? { fontWeight: "bold", fontSize: "33px" }
  //                     //     : { color: "black" }
  //                     // }

  //                     >

  //                     {item.name}

  //                    </div>

  //                   </div>
  //                 );
  //               })}
  //               <div className="buttons" style={{background: "#CF1A38",color:"white",padding:"0 30px",cursor: "pointer",maxWidth:"117px",fontWeight: 700}}>
  //               Explorer
  //               </div>
  //             </Nav>

  //           </Navbar.Collapse>
  //         </Container>
  //       </Navbar>
  //     </div>
  //   </>
  // );
  const hanlefunction = (e) => {
     setinfo(e);
    
  };
  return (
    <>
      <div className="custom_navbar1e mobo">
        <Navbar
          onToggle={setNavExpanded}
          expanded={expanded}
          expand="lg"
          variant="dark"
        >
          <Container fluid className="p-0 mobile">
            <Navbar.Brand href="/">
              {" "}
              <img
                src={logo}
                alt=""
                style={{ width: "225px", height: "40.26px" }}
              />
            </Navbar.Brand>
            <img
              className="edit"
              onClick={() => history.push(`/`)}
              style={{ width: "225px", height: "40.26px" }}
              alt=""
              src={mobo}
            />
            {expanded ? (
              <Navbar.Toggle
                onClick={() => setExpanded(!expanded)}
                aria-controls="offcanvasNavbar"
              >
                <img style={{ width: "17px" }} src={closemark} alt="" />
              </Navbar.Toggle>
            ) : (
              <Navbar.Toggle
                onClick={() => setExpanded(!expanded)}
                aria-controls="offcanvasNavbar"
              />
            )}

            {expanded ? (
              <Navbar.Collapse id="responsive-navbar-nav">
                {/* <Nav className="me-auto">
   
    
              </Nav> */}
                <Nav>
                  {store.map((item) => {
                    return (
                      <>
                        {/* <Nav.Link
                          onClick={() => handler(item.name)}
                          style={
                            info === item.name
                              ? {
                                  fontWeight: "bold",
                                  color: "#334480 !important",
                                }
                              : { color: "#334480 !important" }
                          }
                        >
                          {item.name}
                        </Nav.Link> */}
                      </>
                    );
                  })}
                </Nav>
              </Navbar.Collapse>
            ) : (
              ""
            )}
          </Container>
        </Navbar>
      </div>


    </>
  );
}
