import React from 'react'
import "./AppPage.css"
import affiliate1 from "../Image/affiliate1.svg"
import connection from "../Image/connection.svg"
import engagement from "../Image/engagement.svg"
import linked from "../Image/linked.svg"


function AppsPage() {
  return (
    <div className='main'>
        <div className='nav-bar2'>
          <div className='nav-bar2-inside'>
          <a className='anchor-link' href="" >All Apps</a>
           <a className='anchor-link' href="" >For EveryOne</a>
           <a className='anchor-link' href="" >For influencers</a>
           <a className='anchor-link' href="" >For Brands</a>
          </div>
           
        </div>
        <div className='sub-main'>
        <div className='affiliate' >
          <div className='affiliate-left'>
               <div className='affiliate-left-inside'>
                 <span >Monitize Your </span><br /><span>Social</span><span className='bolder'> Network</span>
                 <div className='affiliate-text'>
                Affiliate App Is The World's First Network Tracking & Monetization Platform . Grow Your Network By Adding Contacts To The App And Get Paid Everytime They Interact With Their Favorite Brands.
               </div>
               </div>
              <a href="https://affiliate.app/" target="_blank" > <button className='affiliate-button'>Website</button></a>
          </div>
         
          <div className='affiliate-right'>
          <img id='image' src={affiliate1} alt="" />
        </div>
        </div>


        <div className='connection'>
          <div className='connection-left'>
            <img id='image' src={connection} alt="" />

          </div>
               <div className='connection-right'>
                  <div className='connection-right-inside'>
                  <span >Monitize Your </span><br /><span>Social</span><span className='bolder'> Audience</span>
                  <div className='connection-text'>
                       Connection.App IS A Social Platform Wich Enables Influencers To Charge MembersDhips And Sell NFT's To Grant Access To Excliusive Content And Personal Engagements.
                  </div>
                  </div>
                  <a href="https://connection.app/" target="_blank" > <button className='connection-button'>Website</button></a>
               </div>
        </div>


        
        <div className='engagement' >
          <div className='engagement-left'>
               <div className='engagement-left-inside'>
                 <span >Turn Your Customers Into </span><span className='bolder'> Affiliates</span>
                 <div className='engagement-text'>
                Engagement App Is A Social CRM Platform Wich Enables Brands To create customized Compensaton Plans For Their Product & Services . Affiliates Are Then Able. 
               </div>
               </div>
              <a href="https://engagement.app/" target="_blank" > <button className='engagement-button'>Website</button></a>
          </div>
         
          <div className='engagement-right'>
          <img id='image' src={engagement} alt="" />
        </div>
        </div>
        

        <div className='linked'>
          <div className='linked-left'>
            <img src={linked} alt="" />

          </div>
               <div className='linked-right'>
                  <div className='linked-right-inside'>
                  <span >Meet </span><span className='bolder'> India's</span><span>  Hottest Startups</span>
                  <div className='linked-text'>
                      If you are lokking for cutting edge companies that are disrupting the indian market than look no further we have partnered with indianinvester.com to create a oppurtunity to startups issuing ShareTokens to make live pitches and launch their equity offerings.
                  </div>
                  </div>
                  <a href="https://linked.app/" target="_blank" > <button className='linked-button'>Website</button></a>
               </div>
        </div>

        </div>


    </div>
   
  )
}

export default AppsPage