import "./App.css";
import Nav from "./Component/Navabar";
import Home from "./Pages/Homepage";
import Banner from "./Component/Carer/Carer";
import Pot from "./Component/Listcontiner";
import { Route, Switch, Redirect, useHistory, Router } from "react-router-dom";
import AppsPage from "./Component/AppsPage";
import { MainContext } from "./Component/context";
import { useContext } from "react";
import LandingPage from "./Pages/LandingPage";
function App() {
  const value = useContext(MainContext);

  const closeoverlay = (e) => {
    value.setopencard("translateY(-140%)");
    value.setpagemask("");
    value.setclosedisp("none");
    value.settherightcard("translateY(-140%)");
    value.setOpenSidebar(false)
    value.setRightSidebar(false)
    // value.SetIsMenuOpened("translateX(-100%)");
    // value.setpagemask("")
    // value.setcloserightdisp("none")
    // value.seticondisp("none")
  };

  return (
    <>
      <div className="main-wrapper" onMouseDown={closeoverlay}>
        {/* {window.location.href.split("/").pop() !== "doge" ? <Navs /> : ""} */}
        {/* <Banner />
        <Nav active="About" /> */}

        <Switch>
          <Route exact path="/" render={() => <LandingPage />} />
          <Route
            exact
            path="/investors/portfolio/sharetokens"
            render={() => <Pot />}
          />
          <Route exact path="/investors/about" render={() => <LandingPage />} />

          <Route exact path="/investors/App" render={() => <AppsPage />} />
        </Switch>
      </div>
    </>
  );
}

export default App;
