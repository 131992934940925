import React, { useState } from "react";
import "./token.scss";
import token from "../../static/Image/token.svg";
import Modal from "react-bootstrap/Modal";
import upid from "../../static/Image/upid.svg";
import visa from "../../static/Image/visa.svg";
import bank from "../../static/Image/bank.svg";
import tokenback from "../../static/Image/tokenback.svg";
import goldback from "../../static/Image/goldback.svg";
export default function Index() {
  const [result, setresult] = useState(0);
  const [tab, settab] = useState("");
  const [subscription, setsubscription] = useState(1);
  const [show, setshow] = useState(false);
  const [subtotal, setsubtotal] = useState(0);
  const [selectItem, setselectItem] = useState("");

  const handle = (e) => {
    if (tab === "sliver") {
      setsubscription(e.target.value);
      setresult(e.target.value * 2000);
    }
    if (tab === "gold") {
      setsubscription(e.target.value);
      setresult(e.target.value * 50000);
    }
  };

  const handletabSliver = (e) => {
    settab(e);
    setresult(2000);
    setshow(true);
    setsubtotal(2000);
  };
  const handletabGold = (e) => {
    settab(e);
    setresult(50000);
    setshow(true);
    setsubtotal(50000);
  };
  const data = [
    {
      name: "UPI",
      img: upid,
    },
    {
      name: "Credit Card",
      img: visa,
    },
    {
      name: "Crypto",
      img: bank,
    },
  ];
  return (
    <>
      <div className="tokenOne" id="Tokens">
        <div className="left">
          <h5>
            Invest Into Our CSOP & <span>Become</span>
          </h5>
          <h1>A Silver Card Member</h1>
          <h3>Become A Silver Card Member</h3>
          <label onClick={() => handletabSliver("sliver")}>How It Works</label>
        </div>
        <img src={token} />
      </div>
      <div className="token" id="Tokens">
        <div className="left">
          <h5>
            Invest Into Our ShareToken & <span>Become</span>
          </h5>
          <h1>A Gold Card Member</h1>
          <h3>Become A Gold Card Member</h3>
          <label onClick={() => handletabGold("gold")}>How It Works</label>
        </div>
        <img src={token} />
      </div>
      <div className="tokenTwo">
        <div className="left">
          <h5>
            Invest Into Our PSOP & <span>Get</span>
          </h5>
          <h1>10K Advertising Credits</h1>
          <h3>Get 10K Advertising Credits</h3>
          <label>How It Works</label>
        </div>
        <img src={token} />
      </div>
      <div className="custommodel">
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={() => setshow(false)}
        >
          <>
            <div className="tokenmodel">
              <div className="tokensection">
                <div className="left">
                  <h1>Checkout</h1>
                  <div className="checkoutCard">
                    {data.map((item) => {
                      return (
                        <div
                          className="inner"
                          onClick={() => setselectItem(item.name)}
                          style={{
                            boxShadow:
                              item.name === selectItem
                                ? "0px 4px 4px rgba(0, 0, 0, 0.25)"
                                : "0px 0px 0px rgba(0, 0, 0, 0.25)",
                          }}
                        >
                          <img src={item.img} />
                          <p>{item.name}</p>
                        </div>
                      );
                    })}
                  </div>
                  <div className="cal">
                    <h5>Subtotal</h5>
                    <h6>₹{subtotal.toFixed(2)}</h6>
                  </div>
                  <div className="cal">
                    <h5>Subscriptions</h5>
                    <input value={subscription} onChange={handle} />
                  </div>
                  <div className="line"></div>
                  <div className="cal">
                    <h5>Total Amount</h5>
                    <h6>₹{result.toFixed(2)}</h6>
                  </div>
                  <label
                    style={{
                      opacity: selectItem === "" ? 0.5 : 1,
                    }}
                  >
                    Make Payment
                  </label>
                </div>
                <div
                  className="right"
                  style={{
                    backgroundImage: `url(${
                      tab === "sliver" ? tokenback : goldback
                    })`,
                  }}
                ></div>
              </div>
            </div>
          </>
        </Modal>
      </div>
    </>
  );
}
