import React from "react";
import "./service.scss";
export default function index() {
    
  return (
    <div className="Service" id="Services" >
      <div className="innerOne">
        <h1>
          Our <br /> Services
        </h1>
      </div>
      <div className="right">
        <div className="innerTwo">
          <h4>01. Discovery</h4>
          <p>
            If we obtain your offering private placement within an institutional
            investor. If we obtain your offering private placement within an
            institutional investor
          </p>
        </div>
        <div className="innerTwo">
          <h4>02. Loyalty </h4>
          <p>
            If we obtain your offering private placement within an institutional
            investor. If we obtain your offering private placement within an
            institutional investor
          </p>
        </div>
        <div className="innerTwo">
          <h4>03. Event Financing </h4>
          <p>
            If we obtain your offering private placement within an institutional
            investor. If we obtain your offering private placement within an
            institutional investor
          </p>
        </div>

        <div className="innerTwo">
          <h4>04. Marketing Software</h4>
          <p>
            If we obtain your offering private placement within an institutional
            investor. If we obtain your offering private placement within an
            institutional investor
          </p>
        </div>
        <div className="innerTwo">
          <h4>05. P2P Wagering</h4>
          <p>
            If we obtain your offering private placement within an institutional
            investor. If we obtain your offering private placement within an
            institutional investor
          </p>
        </div>
        <div className="innerTwo">
          <h4>06. IP Licensing</h4>
          <p>
            If we obtain your offering private placement within an institutional
            investor. If we obtain your offering private placement within an
            institutional investor
          </p>
        </div>
      </div>
    </div>
  );
}
