
import React,{useEffect,useState} from 'react'
import axios from 'axios'
import './protfolio.scss'

export default function Protfolios() {
    const [items,setitems]=useState("ShareTokens")
    const [show,setshow]=useState(true)
const[info,setinfo]=useState([])
    const data=[
        {
            name:"ShareTokens"
        },
        {
            name:"Crypto"
        },
        {
            name:"Tokens"
        },
        {
            name:"NFT’s"
        },
        {
            name:"Stocks"
        },
    ]

    useEffect(() => {
        setshow(false)
        axios
            .get("https://comms.globalxchange.com/gxb/app/gxlive/operator/brands/get?parent_group_id=inrgroup")
            .then((res) => {
                setinfo(res.data.brands)
                setshow(true)
            });
        
      }, []);
    return (
    <div className='profolio'>
        <div className='left'>
<div className='listitem'>
    {
    data.map(item=>{
        return(
            <h1 style={item.name==="ShareTokens"?{fontWeight:"bold"}:{}}> {item.name}</h1>
        )
    })
    }
</div>
        </div>
<div className='right'>

    {
        show?
    
<div className='assad'>
{
    info.map(item=>{
        return(
            <div className="cards">
<img src={item.other_data.coloredfulllogo} alt=""/>
            </div>
        )
    })
}
   
</div>
:

<div className='assad'>
{
    [1,2,3,3,4,4,5,5].map(item=>{
        return(
            <div className="skeleton">

            </div>
        )
    })
}
   
</div>
}
</div>
    </div>
  )
}
