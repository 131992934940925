import React, { Component, createContext, useState } from "react";

export const MainContext = createContext();

const MainContextProvider = ({ children }) => {
    const [opencard, setopencard] = useState("translateY(-290%)")
    const [therightcard, settherightcard] = useState("translateY(-290%)")
    const [closecard, setclosecard] = useState("")
    const [pagemask, setpagemask] = useState("")
    const [closedisp , setclosedisp] = useState("none")
    const [closerightdisp , setcloserightdisp] = useState("none")
    const [icondisp , seticondisp] = useState("none")
    const [openSidebar, setOpenSidebar] = useState(false)
    const [rightSidebar,setRightSidebar]=useState(false)
    const value = {
        opencard,
        closecard,
        pagemask,
        therightcard,
        closedisp,
        closerightdisp,
        icondisp,
        setopencard,
        setclosecard,
        setpagemask,
        settherightcard,
        setclosedisp,
        setcloserightdisp,
        seticondisp,openSidebar, setOpenSidebar,rightSidebar,setRightSidebar
    }
    return (
        <MainContext.Provider value={value}>
            {children}
        </MainContext.Provider>
    );
}

export default MainContextProvider;

export const MainContextConsumer = MainContext.Consumer