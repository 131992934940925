import React from "react";
import "./flip.scss";
import indianNights from "../../static/Image/indianNights.png";
import whiteIndianNights from "../../static/Image/whiteIndianNights.svg";
import social from "../../static/Image/social.png";
import whitesocial from "../../static/Image/whitesocial.svg";
import colorsocial from "../../static/Image/colorsocial.svg";
import bets from "../../static/Image/bets.png";
import whitebets from "../../static/Image/whitebets.svg";
import colorbets from "../../static/Image/colorbets.svg";

import partices from "../../static/Image/partices.png";
import whiteparties from "../../static/Image/whiteparties.svg";
import colorparties from "../../static/Image/colorparties.svg";

import promotitng from "../../static/Image/promotitng.png";
import whitepromoting from "../../static/Image/whitepromoting.svg";
import promotingcolor from "../../static/Image/promotingcolor.svg";

import promance from "../../static/Image/promance.png";
import whitepromance from "../../static/Image/whitepromance.svg";
import proformancecolor from "../../static/Image/proformancecolor.svg";

import indiannightsback from "../../static/Image/indiannightsback.svg";
import arrow from "../../static/Image/arrow.svg";

import momeries from "../../static/Image/momeries.svg";
import memoriesWhite from "../../static/Image/memoriesWhite.svg";
import memoriesColor from "../../static/Image/memoriesColor.svg";

import backtickets from "../../static/Image/backtickets.svg";
import tikcets from "../../static/Image/tikcets.svg";
import whitetickets from "../../static/Image/whitetickets.svg";

export default function index() {
  return (
    <div id="Products">
      <div className="landingpage">
        <h5>We Are Building The Future Of</h5>
        <h6>Future Of</h6>
        <h1>Live <span>Entertainment</span></h1>
        <div className="props-object-landling">
          <label>Invest Today</label>
          <label>For Businesses</label>
        </div>
      </div>
      <div className="innerbannerLanding">
        <h6>Marketplaces</h6>
        <h5>Launch A Marketplace In Your Country</h5>
      </div>
      <div className="cardSection">
      <div class="flip flip-vertical">
        <div class="front" style={{ backgroundImage: `url(${indianNights})` }}>
          <img src={indiannightsback} />
          <h1 class="text-shadow">IndianNights</h1>
        </div>
        <div class="back">
          <img className="backImg" src={whiteIndianNights} />
          <h2 class="text-shadow">IndianNights</h2>
          <div className="about-secton">
            <h3>ABOUT</h3>
            <div className="box">
              <img src={arrow} />
            </div>
          </div>
        </div>
      </div>
      <div class="flip flip-vertical">
        <div class="front" style={{ backgroundImage: `url(${social})` }}>
          <img src={whitesocial} />
          <h1 class="text-shadow">Tickets Social</h1>
        </div>
        <div class="back">
          <img className="backImg" src={colorsocial} />
          <h2 class="text-shadow">Tickets Social</h2>
          <div className="about-secton">
            <h3>ABOUT</h3>
            <div className="box">
              <img src={arrow} />
            </div>
          </div>
        </div>
      </div>
      <div class="flip flip-vertical">
        <div class="front" style={{ backgroundImage: `url(${bets})` }}>
          <img src={whitebets} />
          <h1 class="text-shadow">IndianBets</h1>
        </div>
        <div class="back">
          <img className="backImg" src={colorbets} />
          <h2 class="text-shadow">IndianBets</h2>
          <div className="about-secton">
            <h3>ABOUT</h3>
            <div className="box">
              <img src={arrow} />
            </div>
          </div>
        </div>
      </div>
      </div>
      <div className="innerbannerLanding">
        <h6>Applications</h6>
        <h5>Launch A Marketplace In Your Country</h5>
      </div>
      <div className="cardSection">
      <div class="flip flip-vertical">
        <div class="front" style={{ backgroundImage: `url(${partices})` }}>
          <img src={whiteparties} />
          <h1 class="text-shadow">Parties</h1>
        </div>
        <div class="back">
          <img className="backImg" src={colorparties} />
          <h2 class="text-shadow">Parties</h2>
          <div className="about-secton">
            <h3>ABOUT</h3>
            <div className="box">
              <img src={arrow} />
            </div>
          </div>
        </div>
      </div>

      <div class="flip flip-vertical">
        <div class="front" style={{ backgroundImage: `url(${promotitng})` }}>
          <img src={whitepromoting} />
          <h1 class="text-shadow">Promoting</h1>
        </div>
        <div class="back">
          <img className="backImg" src={promotingcolor} />
          <h2 class="text-shadow">Promoting</h2>
          <div className="about-secton">
            <h3>ABOUT</h3>
            <div className="box">
              <img src={arrow} />
            </div>
          </div>
        </div>
      </div>

      <div class="flip flip-vertical">
        <div class="front" style={{ backgroundImage: `url(${promance})` }}>
          <img src={whitepromance} />
          <h1 class="text-shadow">Performances</h1>
        </div>
        <div class="back">
          <img className="backImg" src={proformancecolor} />
          <h2 class="text-shadow">Performances</h2>
          <div className="about-secton">
            <h3>ABOUT</h3>
            <div className="box">
              <img src={arrow} />
            </div>
          </div>
        </div>
      </div>
      </div>
      <div className="innerbannerLanding">
        <h6>Protocols</h6>
        <h5>Launch A Marketplace In Your Country</h5>
      </div><div className="cardSection">

      <div class="flip flip-vertical flipCard">
        <div class="front" style={{ backgroundImage: `url(${momeries})` }}>
          <img src={memoriesWhite} />
          <h1 class="text-shadow">Memories</h1>
        </div>
        <div class="back">
          <img className="backImg" src={memoriesColor} />
          <h2 class="text-shadow">Memories</h2>
          <div className="about-secton">
            <h3>ABOUT</h3>
            <div className="box">
              <img src={arrow} />
            </div>
          </div>
        </div>
      </div>

      <div class="flip flip-vertical flipCard">
        <div class="front" style={{ backgroundImage: `url(${backtickets})` }}>
          <img src={whitetickets} />
          <h1 class="text-shadow">Tickets</h1>
        </div>
        <div class="back">
          <img className="backImg" src={tikcets} />
          <h2 class="text-shadow">Tickets</h2>
          <div className="about-secton">
            <h3>ABOUT</h3>
            <div className="box">
              <img src={arrow} />
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}
