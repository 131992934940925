import React from "react";
import R from "../../static/Image/r.svg";
import N from "../../static/Image/n.svg";
import S from "../../static/Image/s.svg";
import M from "../../static/Image/m.svg";
import "./team.scss";
export default function index() {
  return (
    <>
      <div className="teamSectiom" id="Team">
        <div className="Team-card">
          <img src={R} />
          <div className="innermobile">
          <h1>Rohith G</h1>
          <p>CEO</p>
        </div>
        </div>
        <div className="Team-card">
          <img src={N} />
          <div className="innermobile">
          <h1>Naane B</h1>
          <p>CMO</p>
        </div>
        </div>
        <div className="Team-card">
          <img src={S} />
          <div className="innermobile">
          <h1>Shorupan P</h1>
          <p>CTO</p>
        </div>
        </div>
        <div className="Team-card">
          <img src={M} />
          <div className="innermobile">
          <h1>Sanjay M</h1>
          <p>VP Of Sales</p>
        </div>
        </div>
      </div>

      <div className="bottomTeam">
        <div className="inner">
          <h1>Rohith G</h1>
          <p>CEO</p>
        </div>
        <div className="inner">
          <h1>Naane B</h1>
          <p>CMO</p>
        </div>

        <div className="inner">
          <h1>Shorupan P</h1>
          <p>CTO</p>
        </div>
        <div className="inner">
          <h1>Sanjay M</h1>
          <p>VP Of Sales</p>
        </div>
      </div>
    </>
  );
}
