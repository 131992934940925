import React from 'react'
import Flipcards from '../Component/FlipCards'
import Service from '../Component/Service'
import NavSection from '../Component/NavbarSextion'
import Token from '../Component/Token'
import Team from '../Component/Team'
import MobileNav from '../Component/Navabar'
export default function LandingPage() {
  return (
  <>
  <MobileNav/>
  <NavSection/>
  <Flipcards/>
  <Service/>
  <Token/>
  <Team/>
  </>
  )
}
