import React, { useEffect } from "react";
import "./navSaction.scss";
import Scrollspy from "react-scrollspy";
import logo from "../../static/Image/logo.svg";
export default function index() {
  return (
    <div className="topFixeNav">
      <img src={logo} />
      <div className="rightside">
        <Scrollspy
          items={["Products", "Services", "Tokens", "Roadmap", "Team"]}
          currentClassName="is-current"
          offset={-73}
        >
          <li>
            <a href="#Products">Products</a>
          </li>
          <li>
            <a href="#Services">Services</a>
          </li>
          <li>
            <a href="#Tokens">Tokens</a>
          </li>
          <li>
            <a href="#Roadmap">Roadmap</a>
          </li>
          <li>
            <a href="#Team">Team</a>
          </li>
          <label>Contact Us</label>
        </Scrollspy>
      </div>
    </div>
  );
}
